<template lang="pug">
button.AppButton(:class="disabled && 'disabled'" :type="type")
  slot
</template>

<script>

export default {
  name: 'AppButton',
  сomponents: {
    // Gear
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    }
  }
}
</script>

<style lang="scss" scoped>
.AppButton {
  width: fit-content;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 0.50;
  }
  &.disabled {
    background-color: #E4EBEA;
    cursor: not-allowed;
    &:hover, &:active {
      opacity: 1;
    }
  }
  @media (hover: none) {
    &:hover {
      opacity: 1; // Отменяет стиль :hover для touch устройств
    }
  }
}
</style>
