<template lang="pug">
AppButton.Button(
  :class="[`Button--${size}`, `Button--${fixedSize ? 'fixed' : ''}`, `Button--${fullWidth ? 'full' : ''}`, `Button--${color}`]" 
  :disabled="disabled"
  :type="type"
)
  template(v-if="$slots.default")
    slot
  div {{title}}
    template(v-if="loading")
      Loader.loader
</template>

<script>
import AppButton from '@/components/ui/AppButton/index.vue'
import Loader from '@/components/ui/Loader/index.vue'

export default {
  name: 'Button',
  components: {
    AppButton,
    Loader
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    fixedSize: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      validator (value) {
        return ['lg', 'md', 'sm', 'xxs'].includes(value)
      },
      default: 'lg'
    },
    color: {
      type: String,
      validator (value) {
        return ['default', 'warning', 'primary', 'danger'].includes(value)
      },
      default: 'default'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    }
  }
}
</script>

<style lang="scss" scoped>
.Button {
  border: none;
  color: $white;
  border-radius: get-vw(18);
  cursor: pointer;
  text-align: center;
  width: auto;
  display: flex;
  justify-content: center;
  position: relative;
}
.Button.Button--full {
  width: 100%;
  flex: 1;
}

.Button--lg {
  @include headline-h7;
  padding: get-vw(28.5) get-vw(48);
  .loader {
  margin-left: get-vw(16);
  }
}
.Button--lg.Button--fixed {
  width: get-vw(738);
}
.Button--md {
  @include headline-h4;
  padding: get-vw(20.5) get-vw(48);
  .loader {
  margin-left: get-vw(8);
  }
}
.Button--md.Button--fixed {
  width: get-vw(472);
}
.Button--sm {
  @include headline-h4;
  padding: get-vw(20.5) get-vw(48);
  .loader {
  margin-left: get-vw(8);
  }
}
.Button--sm.Button--fixed {
  width: get-vw(332);
}
.Button--xxs {
  @include text-t7-meduim;
  padding: get-vw(10) get-vw(20.5);
}
.Button--default {
  background-color: $primary;
}
.Button--warning {
  background-color: $signal-red;
}
.Button--primary {
  background-color: $white;
  color: $button-xs-text;
  &:hover {
    background-color: $text-button-hover;
    opacity: 100;
  }
  &:active {
    background-color: $button-text-press;
    opacity: 100;
  }
}
.Button--danger {
  background-color: $white;
  color: $button-xs-text;
  &:hover {
    background-color: $text-button-hover;
    opacity: 100;
  }
  &:active {
    background-color: $notification-red;
    color: $signal-red;
    opacity: 100;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include tablet {
  .Button {
    border-radius: get-vw(12, "md");
  }
  .Button--lg.Button--fixed {
    width: get-vw(298, "md");
  }
  .Button--lg, .Button--md, .Button--sm {
    @include text-t8-medium-m;
    padding: get-vw(11.5, "md") 0;
    &.Button--fixed {
      width: get-vw(265, "md");
    }
  }
  .Button--sm {
    &.Button--fixed {
      width: get-vw(231, "md");
    }
  }
  .Button--xxs {
    @include text-t10("md");
    border-radius: get-vw(6, 'md');
    padding: get-vw(4, "md") get-vw(20.5, "md");
  }
}

@include mobile {
  .Button {
    border-radius: get-vw(8, "sm");
  }

  .loader {
    width: get-vw(16, "sm");
    height: get-vw(16, "sm");
  }

  .Button--lg {
    padding: get-vw(10, "sm") 0;
    @include text-t8-semibold-m("sm");
    .loader {
      margin-left: get-vw(8, "sm");
      margin-top: get-vw(-4, "sm");
    }
  }

  .Button--lg.Button--fixed {
    width: get-vw(265, "sm");
  }

  .Button--md {
    padding: get-vw(10, "sm") 0;
    @include text-t8-semibold-m("sm");
    .loader {
      margin-left: get-vw(8, "sm");
      margin-top: get-vw(-4, "sm");
    }
  }

  .Button--md.Button--fixed {
    width: get-vw(265, "sm");
  }

  .Button--sm {
    border-radius: get-vw(6, "sm");
    padding: get-vw(7, "sm") 0;
    @include text-t4-semobold-s;
  }

  .Button--sm.Button--fixed {
    width: get-vw(119, "sm");
  }

  .Button--xxs {
    @include text-t4-semobold-s;
    padding: get-vw(7, "sm") get-vw(10, "sm");
  }
}
</style>
